<template>
	<div>
        <div class="card card-custom gutter-b mt-10">
            
                <div class="card-body">
      <!--<div class="d-flex mb-9">
         <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
                <img src="media/users/300_21.jpg" alt="image"></div>
            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                <span class="font-size-h3 symbol-label font-weight-boldest">JM</span></div>
         </div>
         <div class="flex-grow-1">
            <div class="d-flex justify-content-between flex-wrap mt-1">
               <div class="d-flex mr-3">
                    <a href="#" class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">Jason Muller</a><a href="#"><i class="flaticon2-correct text-success font-size-h5"></i></a></div>
               <div class="my-lg-0 my-3"><a href="#" class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">ask</a><a href="#" class="btn btn-sm btn-info font-weight-bolder text-uppercase">hire</a></div>
            </div>
            <div class="d-flex flex-wrap justify-content-between mt-1">
               <div class="d-flex flex-column flex-grow-1 pr-8">
                  <div class="d-flex flex-wrap mb-4"><a href="#" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-new-email mr-2 font-size-lg"></i>jason@siastudio.com</a>
                    <a href="#" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-calendar-3 mr-2 font-size-lg"></i>PR Manager </a>
                    <a href="#" class="text-dark-50 text-hover-primary font-weight-bold"><i class="flaticon2-placeholder mr-2 font-size-lg"></i>Melbourne</a></div>
                  <span class="font-weight-bold text-dark-50">I distinguish three main text objectives could be merely to inform people.</span><span class="font-weight-bold text-dark-50">
                    A second could be persuade people.You want people to bay objective</span>
               </div>
               <div class="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8">
                  <span class="font-weight-bold text-dark-75">Progress</span>
                  <div class="progress progress-xs mx-3 w-100">
                     <div role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-success" style="width: 63%;"></div>
                  </div>
                  <span class="font-weight-bolder text-dark">78%</span>
               </div>
            </div>
         </div>
      </div>-->
        <br>
        <div class="row">
            <div class="col-md-4">
                <treeselect v-model="CarsSelect" :multiple="false" :options="options"/>
            </div>
            <div class="col-md-3">
                <datetime type="date" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="dateStart" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
            </div>
            <div class="col-md-3">
                <datetime type="date" :placeholder="$t('TEXT_ALL.DATE_END')" v-model="dateEnd" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
            </div>
            <div class="col-md-2">
                <button type="submit" class="btn btn-success font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="GetTrajet();"> Appliquer </button>
            </div>
        </div>
        <br>
      <div class="separator separator-solid"></div>
      <div class="d-flex align-items-center flex-wrap mt-8">
         <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4"><i class="flaticon-chat-1 display-4 text-muted font-weight-bold"></i></span>
            <div class="d-flex flex-column text-dark-75"><span class="font-weight-bolder font-size-sm">kilométrage</span>
            <span class="font-weight-bolder font-size-h5"><span class="text-dark-50 font-weight-bold"></span>{{ mileage }} KM</span></div>
         </div>
         <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4"><i class="flaticon-confetti display-4 text-muted font-weight-bold"></i></span>
            <div class="d-flex flex-column text-dark-75"><span class="font-weight-bolder font-size-sm">Nombre des trajets</span>
            <span class="font-weight-bolder font-size-h5"><span class="text-dark-50 font-weight-bold"></span>{{tripNumber}}</span></div>
         </div>
         <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4"><i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i></span>
            <div class="d-flex flex-column text-dark-75"><span class="font-weight-bolder font-size-sm">Temps total de trajet</span>
            <span class="font-weight-bolder font-size-h5"><span class="text-dark-50 font-weight-bold"></span>{{ConvertTime(TimeTrip)}}</span></div>
         </div>
         <!--<div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4"><i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i></span>
            <div class="d-flex flex-column text-dark-75"><span class="font-weight-bolder font-size-sm">temps de trajet</span>
            <span class="font-weight-bolder font-size-h5"><span class="text-dark-50 font-weight-bold"></span>{{ConvertTime(dateEnd - dateStart)}}</span></div>
         </div>
         <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4"><i class="flaticon-file-2 display-4 text-muted font-weight-bold"></i></span>
            <div class="d-flex flex-column flex-lg-fill"><span class="text-dark-75 font-weight-bolder font-size-sm">73 Tasks</span><a href="#" class="text-primary font-weight-bolder">View</a></div>
         </div>
         <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4"><i class="flaticon-chat-1 display-4 text-muted font-weight-bold"></i></span>
            <div class="d-flex flex-column"><span class="text-dark-75 font-weight-bolder font-size-sm">648 Comments</span><a href="#" class="text-primary font-weight-bolder">View</a></div>
         </div>
         <div class="d-flex align-items-center flex-lg-fill mb-2 float-left">
            <span class="mr-4"><i class="flaticon-network display-4 text-muted font-weight-bold"></i></span>
            <div class="symbol-group symbol-hover">
               <div data-toggle="tooltip" title="Mark Stone" class="symbol symbol-30 symbol-circle"><img alt="Pic" src="media/users/300_25.jpg"></div>
               <div data-toggle="tooltip" title="Charlie Stone" class="symbol symbol-30 symbol-circle"><img alt="Pic" src="media/users/300_19.jpg"></div>
               <div data-toggle="tooltip" title="Luca Doncic" class="symbol symbol-30 symbol-circle"><img alt="Pic" src="media/users/300_22.jpg"></div>
               <div data-toggle="tooltip" title="Nick Mana" class="symbol symbol-30 symbol-circle"><img alt="Pic" src="media/users/300_23.jpg"></div>
               <div data-toggle="tooltip" title="Teresa Fox" class="symbol symbol-30 symbol-circle"><img alt="Pic" src="media/users/300_18.jpg"></div>
               <div class="symbol symbol-30 symbol-circle symbol-light"><span class="symbol-label font-weight-bold">5+</span></div>
            </div>
         </div>-->
      </div>
   </div>
</div>

<div class="row">
			<!--begin::Aside-->
			<div class="col-5" id="kt_profile_aside">
				<!--begin::Profile Card-->
				<div class="card card-custom card-stretch scroll ps" style="height:600px;overflow-y: auto !important;background: #eee;">
					<!--begin::Body-->
					<div class="card-body" style="border: 1px solid #eee;padding: 10px !important;display: inline-table;margin: 5px;background: #fff;" v-for="(trajet,i) in this.Trajet" :key="i" @click="GetDetailTrajet(trajet.start.timeStamp,trajet.end.timeStamp);">
						<div class="row" style="height: 50px;">
                            <div class="col-4">
                                <b>
                                   <!-- <font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['fas', 'map-pin']"/>-->

                                    <font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['fas', 'play']"/>
                                    {{moment(parseFloat(trajet.start.timeStamp)).format("DD/MM/YYYY hh:mm:ss")}}
                                </b>

                            </div>
                           
                            <div class="col-8">
                                <div class="d-flex flex-column text-right">
                                    <span class="text-muted font-size-sm font-weight-bolder">{{trajet.start.adresse}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="height: 50px;">
                            <div class="col-4">
                                {{ ConvertTime(trajet.timeRange) }}
                            </div>
                            <div class="col-8">
                                {{FormatedMileage(trajet.mileage)}} KM
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <b>
                                    <font-awesome-icon style="color:#ED1C24" class="mr-2" :icon="['fas', 'stop']"/>
                                    {{moment(parseFloat(trajet.end.timeStamp)).format("DD/MM/YYYY hh:mm:ss")}}
                                </b>

                            </div>
                           
                            <div class="col-8">
                                <div class="d-flex flex-column text-right">
                                    <span class="text-muted font-size-sm font-weight-bolder">{{trajet.end.adresse}} </span>
                                </div>
                            </div>
                        </div>
                        
					</div>
				</div>
			</div>
			<!--end::Aside-->
			<!--begin::Content-->
			<div class="col-7">
				<!--begin::Card-->
				<div class="card card-custom card-stretch p-4" style="height: 600px;">
                    <l-map id="maps" v-model="maps" v-bind:style="{width: '100%',height: Height}" :zoom="zoom"  :center="center" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">                    
                        <v-rotated-marker v-if="StartIcon.length != 0" 
							ref="marker"
							:lat-lng="StartIcon" 
							:icon="icon_start" 
							:rotationAngle="0">
							<!--<l-popup ref="popup" :options="{closeOnClick: false,autoClose: false, openPopup:true}">
								<img style="width: 12px;" :src="itemsDataCar.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" /> <b style="font-size: 10px;">{{GetNameOrMat(itemsDataCar)}}</b>
							</l-popup>-->
						</v-rotated-marker>
                        <v-rotated-marker  v-if="StartIcon.length != 0" 
							ref="marker"
							:lat-lng="EndIcon" 
							:icon="icon_stop" 
							:rotationAngle="0">
							<!--<l-popup ref="popup" :options="{closeOnClick: false,autoClose: false, openPopup:true}">
								<img style="width: 12px;" :src="itemsDataCar.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" /> <b style="font-size: 10px;">{{GetNameOrMat(itemsDataCar)}}</b>
							</l-popup>-->
						</v-rotated-marker>
                        <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.color" :weight="polyline.weight"></l-polyline>
                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                        
                    </l-map>
				</div>
			</div>
			<!--end::Content-->
		</div>
    </div>
</template>

<script>
/*import axios from "axios";
//import Cookie from "@/core/services/localstorage.module";
import functionAssets from "@/core/services/function.assets.module.js";*/
import propertiesCar from "@/core/services/properties.car.module.js";
import axios from "axios";
import { library } from '@fortawesome/fontawesome-svg-core'
import { LMap, LTileLayer,LPolyline} from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
/*
import { faCircle as fasFaCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as farFaCircle } from '@fortawesome/pro-regular-svg-icons'
import { faCircle as falFaCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircle as fadFaCircle } from '@fortawesome/pro-duotone-svg-icons'*/

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { fas} from '@fortawesome/pro-solid-svg-icons'
import { far} from '@fortawesome/pro-regular-svg-icons'
//import {  } from '@fortawesome/pro-solid-svg-icons'

library.add(fas,far)

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

import Cookie from "@/core/services/localstorage.module";
import moment from 'moment'; 
moment.locale(Cookie.GetCookie('language'));
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});


export default {
	components: {
        datetime: Datetime,
        'font-awesome-icon': FontAwesomeIcon,
        LMap,
		LTileLayer,
        LPolyline,
        'v-rotated-marker': Vue2LeafletRotatedMarker,
    },
	data: () => ({
		IdSubmited:"",
		id:"",
		Trajet: '',
        TrajetDetail : '',
        moment: moment,
        Height: '1000px',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		attribution:
		'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		zoom: 8,
		center: [35.3264774,9.5552708],
		bounds: null,
        StartIcon : [],
        EndIcon : [],
		maps:null,
        polyline: {
            latlngs: [],
            weight: 5,
            color: '#069fb6'
        },
        icon_start: L.icon({
			iconUrl: 'media/icon/tripview_af.png',
			iconSize: [30, 42],
			iconAnchor: [18, 37]
		  }),
        icon_stop: L.icon({
			iconUrl: 'media/icon/tripview_bf.png',
			iconSize: [30, 42],
			iconAnchor: [0, 37]
		  }),

        propertiesCar:propertiesCar,
        mindata : [],
        mindataShow : [],
        mindataShowGp : [],
        CarsSelect: [],
        options: [],
        newDate : new Date().toJSON(),
		dateStart : "",
		dateEnd : "",
        tripNumber : 0,
        mileage : 0,
    }),
	destroyed() {

	},
	computed: {
        
    },
	async mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
        this.dateStart = new Date().toJSON();
		this.dateEnd = new Date().toJSON();
        setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 1000);
		//console.log();
		if(this.$el.attributes){
			if(typeof(this.$el.attributes.id) != undefined && typeof(this.$el.attributes.id) != "undefined"){
				this.IdSubmited = this.$el.attributes.id.value;
				this.CarsSelect = parseInt(this.IdSubmited);
				this.GetTrajet();
			}
		}
        this.GetCarsList();
		
		
	},
	created() {
		
    },
	methods: {
        ConvertTime (time,timeOnly){
            time = time/1000;
            if (time>=86400 && !timeOnly)
            {
                var jour = Math.floor(time/86400);
                var reste = time%86400;
                var heure =  Math.floor(reste/3600);
                // puis le nombre d'heures
                reste = reste%3600;
                var minute = Math.floor(reste/60);
                var seconde = reste%60;
                var result = jour+'j '+heure+'h '+minute+'m '+Math.floor(seconde)+'s';
            }
            else if (time>=3600)
            {
                heure =  Math.floor(time/3600);
                reste = time%3600;
                minute = Math.floor(reste/60);
                seconde = reste%60;
                result = heure+'h '+minute+'m '+Math.floor(seconde)+'s';
            }
            else if (time<3600 && time>=60)
            {
                minute = Math.floor(time/60);
                seconde = time%60;
                result = minute+'m '+Math.floor(seconde)+'s';
            }
            else if (time < 60) 
            {
                result = time+'s';
            }
            return result;	
        },
        GetCarsList () {
			this.options = propertiesCar.SelectBoxFormat();
		},
        FormatedMileage (mileage) {
			return parseFloat(parseFloat(mileage) / 1000).toFixed(2);
		},
        
        async GetTrajet() {
		
            if(this.dateStart == "" || this.dateEnd =="") {
				alert("Veuillez remplir la date");
				return;
			}
			var dateStart = new Date(moment(this.dateStart).startOf('day').toISOString()).getTime();
			var dateEnd = new Date(moment(this.dateEnd).endOf('day').toISOString()).getTime();
			if(dateStart > dateEnd) {
				alert("Date invalide");
				return;
			}
            if(this.CarsSelect == ""){
                alert("Veuillez sélectionner un véhicule");
                return;
            }
            if(!Number.isInteger(this.CarsSelect)){
                alert("Veuillez sélectionner un véhicule");
                return;
            }
            axios.defaults.headers["Content-Type"] =  "application/json";
		    axios.defaults.withCredentials = true;
            //axios.post("controleur/core/calldataV2.php?id=2043&start=1677625200000&end=1678402799000&which=trip&asyncDriverLogPOI=true").then(async (response) => {
            axios.get("controleur/core/calldataV2.php",{params: {id: this.CarsSelect,start:dateStart,end:dateEnd,which: "trip",asyncDriverLogPOI:"true"}}).then((response) => {
                this.tripNumber = 0;
                this.mileage = 0;
                this.Trajet = response.data.trips;
                this.tripNumber = response.data.trips.length;
                this.TimeTrip = 0;
                for(var i in response.data.trips){
                    if(parseInt(response.data.trips[i].mileage) > 0){
                        this.mileage = this.mileage + parseFloat(this.FormatedMileage(response.data.trips[i].mileage));
                    }
                    if(parseInt(response.data.trips[i].timeRange) > 0){
                        this.TimeTrip = this.TimeTrip + parseInt(response.data.trips[i].timeRange);
                    }
                }  
                this.mileage = this.FormatedMileage(this.mileage);
            }).catch((error) => {    
                if (error.response) {
                    if (error.response.status == 500) {
                        //Cookie.DeleteCookie();
                        this.$router.push('/login');
                    }
                }
            });
        },
        //https://pro.sayarte.ch/2.6/php/controleur/core/calldataV2.php?id=4162&attribute=gps_latitude%2Cgps_longitude%2Cgps_speed%2Cfuel_level%2Cpeak_engine_speed%2Cparam1%2Cgps_heading&start=1678641266000&end=1678642410000+&which=drivescore
        GetDetailTrajet(start,stop) {
            axios.defaults.headers["Content-Type"] =  "application/json";
		    axios.defaults.withCredentials = true;

            var id_car = this.CarsSelect;

            axios.post("controleur/core/calldataV2.php?id="+id_car+"&attribute=gps_latitude,gps_longitude,gps_speed,fuel_level,peak_engine_speed,param1,gps_heading&start="+start+"&end="+stop+"+&which=drivescore").then(async (response) => {
                this.TrajetDetail = response.data.trips;
                this.polyline.latlngs = [];
                var StartIcon=[];
                var endIcon=[];
                for(var i in response.data[id_car].data){
                    this.polyline.latlngs.push([response.data[id_car].data[i].gps_latitude,response.data[id_car].data[i].gps_longitude]);
                    if(StartIcon == ""){
                        StartIcon = [response.data[id_car].data[i].gps_latitude,response.data[id_car].data[i].gps_longitude];
                        this.center = StartIcon;
                    }else{
                        endIcon = [response.data[id_car].data[i].gps_latitude,response.data[id_car].data[i].gps_longitude];
                    }
                }
                this.StartIcon = StartIcon;
                this.EndIcon = endIcon;
                setTimeout(() => {
                    this.center = StartIcon;
                    this.zoom = 10;
                    
                }, 1500);
                
            }).catch((error) => {   
                console.log(error); 
                if (error.response) {
                    if (error.response.status == 500) {
                        Cookie.DeleteCookie();
                        this.$router.push('/login');
                    }
                }
            });
        },
        zoomUpdated (zoom) {
            this.zoom = zoom;
        },
        centerUpdated (center) {
         this.center = center;
        },
        boundsUpdated (bounds) {
         this.bounds = bounds;
        },
    }
}
</script>
